@import '~app/mixins';

* {
  font-family: $font-inter !important;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.clearfix {
  display: inline-block;
}

/* start commented backslash hack \*/
* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block;
}

/* close commented backslash hack */

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.text_primary {
  color: #128292 !important;
}

.text_link_hover{
  border-radius: 999px; 
  &:hover{
    background-color: #DFE1E4; 
  }
  &:active{
    background-color: #BCC1C7; 
  }
}

.text_warning {
  color: #F16722 !important;
}

.text_danger {
  color: #CA1551 !important;
}

.text_gray {
  color: #66707D !important;
}

.text_dark {
  color: #3C4654 !important;
}


.flexBoxWrap {
  display: flex;
  flex-wrap: wrap;
}

.flex1 {
  flex: 1;
}

.unstyled {
  padding: 0;
  margin: 0;
  list-style: none;
}

.clsBorderRight {
  border-right: solid 1px #DFE1E4;
}

.clsBorderLeft {
  border-left: solid 1px #DFE1E4;
}

.clsBorderBottom {
  border-bottom: solid 1px #DFE1E4;
}

.clsBorderTop {
  border-top: solid 1px #DFE1E4;
}

.clsBorderDanger {
  border-color: #CA1551 !important;
}

.clsBorderPrimaryGreen {
  border-color: #128292 !important;
}

a:active,
a:focus,
a:hover {
  text-decoration: none !important;
}

.clsBtnSm {
  padding: 4px 12px;
  border-radius: 999px;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  margin: 0;
  // display: inline-block;
}

.clsBtnDanger {
  background-color: #CA1551 !important;
}

.clsBtnOrng {
  background-color: #F16722 !important;
}

.clsBtnGray {
  background-color: #99A0AA !important;
}

.clsBtnWhite {
  background-color: #fff !important;
}

.mb_12 {
  margin-bottom: 12px;
}

.clsBtnPrimaryGreen {
  background-color: #128292 !important;
  border-radius: 999px;
    &:hover{
      background-color: #0E6673 !important; 
    }
    &:active{
      background-color: #0A464F !important; 
    }
}

.btn_primary {
  padding: 9px 15px;
  background-color: transparent;
  border-radius: 999px;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: #FFFFFF;
  border: none;
  display: inline-block;
}

.btn_lg {
  padding: 13px 35px;
  border-radius: 999px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  color: #FFFFFF;
}

.btn_xl {
  padding: 13px 35px;
  min-width: 211px;
  min-height: 44px;
  border-radius: 999px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  color: #FFFFFF;
}

.responsivetable {
  overflow: visible !important;
}

.clsTable {
  border-radius: 12px;
  border-collapse: separate;
  border-spacing: 0;

  tr {
    vertical-align: middle;

    th,
    td {
      padding: 15px !important;
      font-size: 14px;
      line-height: 14px !important;
      font-weight: 600;
      color: #3C4654 !important;
    }
  }

  thead {
    tr {
      th {
        background-color: #E9EBEE;
        border: 0 !important;

        &:first-child {
          border-top-left-radius: 12px;
        }

        &:last-child {
          border-top-right-radius: 12px;
        }
      }
    }
  }

  tbody {
    tr {
      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 12px;
          }

          &:last-child {
            border-bottom-right-radius: 12px;
          }
        }
      }

      td {
        border-color: #DFE1E4;
        padding: 20px 15px !important;
      }
    }
  }

  &.clsTableBgGray {
    background-color: #F3F5F8;
  }

  &.clsTableDarkHead {
    background-color: #fff;

    thead {
      tr {
        th {
          background-color: #DFE1E4;
        }
      }
    }
  }

  .clsTableActionBtn {
    position: relative;

    &:hover .clsActionDropdown {
      display: block;
    }

    .clsActionIcon {
      width: 30px;
      height: 30px;
      border-radius: 999px;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;

      svg {
        fill: #3C4654;
        margin-left: -1px;
      }

      &:hover {
        background-color: #DFE1E4;
      }

      &:active {
        background-color: #128292;

        svg {
          fill: #fff;
        }
      }
    }

    .clsActionDropdown {
      padding: 4px;
      border-radius: 12px;
      background-color: #fff;
      border: solid 2px #128292;
      box-shadow: 0px 4px 6px 0px #00000029;
      position: absolute;
      z-index: 9999;
      width: 240px;
      top: 35px;
      right: 0;
      display: none;
      transition: all 0.5s ease;

      &.clsOpenPopup {
        visibility: visible;
        opacity: 1;
      }

      li {
        a {
          font-size: 16px;
          font-weight: 600;
          line-height: 19.2px;
          text-align: left;
          padding: 9px 15px;
          border-radius: 8px;
          color: #3C4654;
          display: block;

          &:hover {
            background-color: #DFE1E4;
            color: #3C4654;
          }
        }
      }
    }
  }
}

.clsTableFixHead {
  height: 600px;
  overflow-y: auto;
  border-radius: 12px;

  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #aaa;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 50px;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

.clsTableTxtAlrt {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;

  .clsTableTxt {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
    max-width: 80px;
    margin-right: 5px;
  }
}

.clsPageWrapper {
  position: absolute;
  width: calc(100vw - 0px);
  height: 100%;
  padding-left: 50px;
}

.clsMainHeader {
  padding: 15px;
  background-color: #fff;
  box-shadow: inset 0px 0 0px 1px #DFE1E4;
  min-height: 78px;
  display: flex;
  align-items: center;

  .clsHeaderHeading {
    color: #3C4654;
    font-size: 28px;
    line-height: 28px;
    font-weight: 600;
    margin: 0;
  }

  .clsHeaderSubHeading {
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    color: #66707D;
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.clsBodyMain {
  padding: 24px;
  background-color: #eff2f6;
  height: calc(100vh - 78px);
  overflow-y: auto;
  overflow-x: hidden;
}

.clsCardsMain {
  display: flex;
  justify-content: center;
  width: 1015px;
 margin-left: auto;
 margin-right: auto;
  gap: 24px;

  .clsCardsLeft {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (min-width: 992px) {
      flex: 0 0 339px;
      max-width: 339px;
    }
  }

  .clsCardsRight {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (min-width: 992px) {
      flex: 0 0 676px;
      min-width: 523px;
      max-width: 676px;
    }
  }
}

// Account page 
.clsCard {
  background-color: #fff;
  border-radius: 12px;

  .clsCardHeader {
    padding: 24px 10px 24px 24px;
    // border-bottom: solid 1px #DFE1E4; 
    box-shadow: 0px 1px 0px 0px #DFE1E4;

    .clsCardTitle {
      color: #3C4654;
      font-size: 24px;
      line-height: 24px;
      font-weight: 600;
      margin: 3px 0;
    }

    .clsCardSubTitle {
      color: #66707D;
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
      margin: 0;
    }
  }

  .clsCardTxt {
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    margin: 0;
    color: #66707D;
    padding: 8px 14px;
    border-radius: 999px;
    &:hover{
      background-color: #DFE1E4; 
    }
    &:active{
      background-color: #BCC1C7; 
    }
  }

  .clsCardTxt2{
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    margin: 0;
    color: #66707D;
    padding: 8px 14px; 
    display: flex;
    gap: 4px;
    
    .clsBorder {
      border: 1px solid #BCC1C7;
    }  
  }

  .clsCardTxtGrp {
    display: flex;
    gap: 8px;
  }

  .clsCardBody {
    padding: 24px;
  }

  .clsCardFooter {
    padding: 24px;
    border-top: solid 1px #DFE1E4;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.clsProfileCard {

  .clsCardBody {
    .clsProfileSec {
      display: flex;
      align-items: center;
      gap: 10px;

      .clsProfileImg {
        width: 64px;
        height: 64px;
        border-radius: 999px;
        background-color: #B8DFE6;

        img {
          width: 100%;
          height: 100%;
          border-radius: 999px;
        }
      }

      .clsUserName {
        color: #3C4654;
        font-size: 16px;
        line-height: 16px;
        font-weight: 600;
        margin-bottom: 4px;
      }

      .clsUserPost {
        color: #66707D;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        margin: 0;
      }
    }

    .clsProfileContact {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .clsContactLine1 {
        color: #66707D;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        margin: 0px;
        text-transform: uppercase;
      }

      .clsContactLine2 {
        color: #3C4654;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        text-align: left;
        margin: 0;
      }
    }
  }

  .clsMarketingProfileCardBanner {
    display: block;
    background-color: #128292;
    padding: 18px;
    border-radius: 12px;

    .clsBannerImg {
      width: 208px;
      margin-top: 8px;
      margin-bottom: 16px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .clsBannerContent {
      .clsBannerHeading {
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        text-align: left;
        color: #FFFFFF;
        max-width: 225px;
        margin-bottom: 8px;
      }

      .clsBannerPara {
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        color: #FFFFFF;
        margin: 0;
        max-width: 225px;
      }
    }
  }

  &.clsProfileCard1 {
    // max-height: 462px;
    min-height: 462px;
  }

  &.clsProfileCard2 {
    // max-height: 419px;
    min-height: 419px;

    .clsCardHeader {
      .clsCardTitle {
        margin: 0;
        margin-bottom: 6px;
      }
    }
  }
}
 

.clsPreCreditsCard {
  .clsValue {
    color: #3C4654;
    font-size: 36px;
    line-height: 36px;
    font-weight: 400;
    margin: 0;
  }

  .clsPreCreditsCardBanner {
    position: relative;
    max-width: 291px;
    max-height: 214px;

    .clsBannerImg {
      img {
        width: 100%;
        height: 100%;
      }
    }

    .clsBannerContent {
      position: absolute;
      z-index: 2;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      padding: 18px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: end;

      .clsBannerHeading {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 8px;
      }

      .clsBannerPara {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        margin: 0;
        max-width: 252px;
      }

      .clsBtnWhite{
        &:hover{
          background-color: #128292 !important; 
          color: #fff !important;
        }
        &:active{
          background-color: #0E6673 !important; 
          color: #fff !important;
        }
      }
    }

  }
}

.clsBillingCard {
  .clsBillingList {
    li {
      padding: 20px 0;
      border-bottom: 1px solid #DFE1E4;

      &:first-child {
        padding-top: 0px;
      }

      &:last-child {
        padding-bottom: 0px;
        border: 0;
      }

      .clsBillingListTitle {
        color: #66707D;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        margin: 0;
        text-transform: uppercase;
      }

      .clsBillingCount {
        color: #3C4654;
        font-size: 36px;
        line-height: 36px;
        font-weight: 400;
        margin: 0;
        margin-right: 5px;
      }

      .clsBillingDaysTxt {
        color: #3C4654;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        margin-top: 0px;
        margin-bottom: 3px;
        text-transform: uppercase;
      }

      .clsBillingRenewTxt {
        color: #66707D;
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
        margin: 0;
      }
    }
  }
}

.clsPlanCard {
  .clsProTxt {
    padding-right: 16px;

    h4 {
      color: #128292;
      font-size: 24px;
      line-height: 24px;
      font-weight: 700;
      margin: 0;
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .clsImg {
      width: 66.27px;
      height: 44px;

    }
  }

  .clsTypeTxt {
    padding: 0 16px;
    border-left: 1px solid #DFE1E4;
    border-right: 1px solid #DFE1E4;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;

    h4 {
      color: #66707D;
      font-size: 14px;
      line-height: 14px;
      font-weight: 600;
      margin: 0px;
    }

    h5 {
      color: #3C4654;
      font-size: 16px;
      line-height: 16px;
      font-weight: 600;
      margin: 0;
    }
  }

  .clsSaveTxt {
    padding-right: 0px;
    border: 0;
  }
}

.clsUsageCard {
  .clsUsageList {
    // justify-content: center;
    display: flex;

    li {
      padding: 0 16px;
      display: inline-flex;
      align-items: center;

      .clsUsageContent {
        display: flex;
        align-items: center;
        gap: 12px;

        .clsUsageContentTxt {
          .clsUsageContent1 {
            font-size: 14px;
            font-weight: 600;
            line-height: 14px;
            color: #66707D;
            text-transform: uppercase;
            margin: 0;
            display: flex;
          }

          .clsUsageContent2 {
            font-size: 36px;
            font-weight: 600;
            line-height: 36px;
            color: #3C4654;
            margin-bottom: 8px;
            margin-top: 0;
          }

          .clsUsageContent3 {
            display: flex;
            gap: 4px;

            .clsBorder {
              border: 1px solid #BCC1C7
            }

            h6 {
              font-size: 14px;
              font-weight: 600;
              line-height: 14px;
              color: #66707D;
              text-transform: uppercase;
              margin-bottom: 0;
              margin-top: 0;

              &.clsBorderRight {
                border-color: #BCC1C7;
              }
            }
          }

        }
      }
    }
  }
}

.clsNoteText {
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  color: #66707D;
  margin-bottom: 0;
  max-width: 300px;
}

.clsLeadCard {

  .clsCardBody,
  .clsCardFooter {
    padding: 24px;
  }

  .clsLeadList {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 0;

    li {
      padding: 0 16px;

      .clsLeadContent {
        .clsLeadContent1 {
          font-size: 14px;
          font-weight: 600;
          line-height: 14px;
          color: #66707D;
          margin: 0;
        }

        .clsLeadContent2 {
          font-size: 16px;
          font-weight: 600;
          line-height: 16px;
          color: #3C4654;
          margin: 0;

        }
      }
    }
  }

  .clsLeadBodyContent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    .clsLeadContentTxt {
      .clsLeadContent1 {
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        color: #66707D;
        text-transform: uppercase;
        margin-bottom: 5px;
        margin-top: 0;
      }

      .clsLeadContent2 {
        font-size: 36px;
        font-weight: 600;
        line-height: 36px;
        color: #3C4654;
        margin-bottom: 5px;
        margin-top: 0;
      }

      .clsLeadContent3 {
        display: flex;
        gap: 4px;

        .clsBorder {
          border: 1px solid #BCC1C7
        }

        h6 {
          font-size: 14px;
          font-weight: 600;
          line-height: 14px;
          color: #66707D; 
          text-transform: uppercase;
          margin-bottom: 0;
          margin-top: 0;

          &.clsBorderRight {
            border-color: #BCC1C7;
          }
        }
      }
    }
  }
}

.clsTeamCard {
  .clsTeamList {
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      padding: 0 15px;

      .clsTeamContent {
        display: flex;
        align-items: center;
        gap: 10px;

        .clsTeamContentTxt {
          .clsTeamContent1 {
            font-size: 14px;
            font-weight: 600;
            line-height: 14px;
            color: #66707D;
            text-transform: uppercase;
            margin-bottom: 5px;
            margin-top: 0;
          }

          .clsTeamContent2 {
            font-size: 36px;
            font-weight: 600;
            line-height: 36px;
            color: #3C4654;
            margin-bottom: 0;
            margin-top: 0;
          }
        }
      }
    }
  }

  .clsTeamCardBanner {
    position: relative;
    background-color: #128292;
    border-radius: 12px;

    .clsBannerImg {
      img {
        width: 100%;
        height: 100%;
      }
    }

    .clsBannerContent {
      position: absolute;
      z-index: 2;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      padding: 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: end;

      .clsBannerPara {
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        margin: 0;
        max-width: 570px;
      }
    }

  }
}

// Account page end

// Billing page 

.clsBilingTab {
  padding: 8px 20px;
  list-style: none;
  margin: -24px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: solid 1px #DFE1E4;

  &.clsBilingInnerTab {
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0;
  }

  .clsTabItem {
    margin: 1px;

    .clsTabLink {
      padding: 8px 18px;
      border-radius: 999px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: -0.02em;
      color: #66707D;
      display: inline-block;

      &.active {
        background-color: #128292;
        color: #fff;
        font-weight: 600;
      }

    }
  }
}

.clsBilingTable {
  tr {

    td,
    th {
      &:last-child {
        text-align: right;
      }

      &:nth-child(2) {
        min-width: 300px;
      }
    }
  }

  .clsTxtLine2 {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    color: #66707D;
    margin-top: 5px;
    max-width: 110px;
    display: block;
  }

  .clsPriceTxt {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: right;
    color: #66707D;

    span {
      display: block;
      margin-top: 5px;
    }
  }
}

.clsWalletSection {
  .clsWalletCard {
    background-color: #FFFFFF;
    border-radius: 12px;
    margin-bottom: 20px;

    .clsWalletCardHeader {
      padding: 20px;

      .clsTextLine1 {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: #3C4654;
        margin: 0;
      }

      .clsTextLine2 {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
        text-align: left;
        color: #66707D;
        margin: 0;
        margin-top: 5px;
      }
    }

    .clsWalletCardCollapseBody {
      padding: 15px 10px 10px 10px;

      .clsWalletCardBody {
        padding: 0 20px;

        .clsTextLine1 {
          font-size: 12px;
          font-weight: 600;
          line-height: 12px;
          color: #66707D;
          margin: 0;
        }

        .clsTextLine2 {
          font-size: 14px;
          font-weight: 600;
          line-height: 16.94px;
          color: #3C4654;
          margin: 0;
          margin-top: 5px;
        }
      }

      .clsWalletCardFooter {
        padding-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

// clsBillingModal   
.clsAccPageModals {
  overflow-x: hidden !important;
  padding: 0; 
  background: rgba(0, 0, 0, 0.40);

  .clsModalContent{
    box-shadow: 0px 18px 20px 0px #00000029 !important;  
    border: 0 !important;
    border-radius: 12px !important;
  }

  .clsModalContentBody {
    padding: 32px 64px;
    // display: flex;
    background-color: #EFF2F6;

    &::-webkit-scrollbar {
      width: 10px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      background-color: #aaa;
      border-radius: 50px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5;
      border-radius: 50px;
    }

    .clsModalHeader{
        padding: 27px 0;
    }

    .clsModalTitle {
      font-size: 36px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: -0.02em;
      text-align: center;
      margin: 0;
      color: #3C4654;
    }

    .clsModalTitle2 {
      font-size: 36px;
      font-weight: 600;
      line-height: 36px;
      text-align: center;
      color: #3C4654;
      margin-bottom: 8px;
    }

    .clsModalSubTitle {
      font-size: 16px;
      font-weight: 500;
      line-height: 19.36px;
      text-align: center;
      margin: 0;
      padding-top: 12px;
      color: #66707D;
      max-width: 560px;
      margin: 0 auto;

      &.clsReloadSubTitle {
        max-width: 380px;
      }
    }

    .clsModalSubTitle2 {
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.02em;
      text-align: center;
      color: #3C4654;
      margin: 0;
    }


    .clsModalSubTitle3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      text-align: center;
      color: #66707D;
      margin: 0;
    }

    .clsModalBody {
      padding: 32px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      

      .clsBodyTxtLine1 {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        margin-bottom: 10px;
      }

      .clsPayCard {
        background-color: #FFFFFF;
        border-radius: 12px;
        padding: 16px 24px;
        display: inline-flex;
        // border: solid 1px #FFFFFF;
        transition: border-color 0.3s ease;
        min-width: 251px;
        min-height: 69px; 

        

        .clsCustomCheckbox input[type="checkbox"]:checked~& {
          border-color: #128292;
        }

        &.clsPayCardFull {
          display: flex !important;
        }

        .clsMediaTxt1 {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          color: #3C4654;
          margin-bottom: 0;
        }

        .clsMediaTxt2 {
          font-size: 14px;
          font-weight: 500;
          line-height: 16.8px;
          color: #66707D;
          margin-bottom: 0; 
        }
      }

      .clsDropdownCard {
        background-color: #DFE1E4;
        border-radius: 12px;
        padding: 2px 16px 2px 2px;
        display: flex;
        align-items: center;
        min-width: 300px;
        min-height:73px; 


        &:hover {
          background-color: #BCC1C7;
        }

        &.clsDropdownCardOpen {
          background-color: #128292;
        }
      }

      .clsCustomCheckbox {
        display: flex;
        align-items: center;
        margin-bottom: 0;

        .clsLabeltxtLine1 {
          font-size: 16px;
          font-weight: 600;
          line-height: 19.36px;
          color: #3C4654;
          margin-bottom: 0;
          display: block;
        }

        .clsLabeltxtLine2 {
          font-weight: 500;
          color: #66707D;
          margin-bottom: 0;
        }

        input[type=radio],
        input[type=checkbox] {
          position: relative;
          border: 1px solid #DFE1E4;
          background-color: #fff !important;
          border-radius: 4px;
          background: none;
          cursor: pointer;
          line-height: 0;
          margin-right: 10px;
          margin-top: 0;
          outline: 0;
          padding: 0 !important;
          vertical-align: text-top;
          height: 24px;
          width: 24px;
          -webkit-appearance: none;

          &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: 16px;
            height: 16px;
            background-image: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 24 24%22 fill=%22none%22 stroke=%22white%22 stroke-width=%222%22 stroke-linecap=%22round%22 stroke-linejoin=%22round%22%3E%3Cpath d=%22M20 6L9 17l-5-5%22/%3E%3C/svg%3E');
            z-index: 2;
            transform: translate(-50%, -50%);
            opacity: 0;
          }
        }

        input[type=radio] {
          border-radius: 999px;

          &:before {
            content: '';
            background-image: none;
            background-color: #128292;
            border-radius: 999px;
            width: 13px;
            height: 13px;
          }
        }

        input[type=checkbox]:checked {
          border: solid 1px #128292;
          background-color: #128292 !important;

          &:before {
            opacity: 1;
          }
        }

        input[type=radio]:checked {
          background-color: #fff;
          border: solid 1px #bcc1c7;

          &:before {
            opacity: 1;
          }
        }

      }

      .clsRequiredTxt {
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        text-align: left;
        color: #66707D;
        margin-bottom: 16px;
      }

      .clsFormGroup {
        .clsFormLabel {
          font-size: 16px;
          font-weight: 600;
          line-height: 16px;
          text-align: left;
          color: #3C4654;
          margin-bottom: 8px;
        }

        .clsFormControl {
          border-radius: 12px;
          border: solid 1px #DFE1E4;
          background-color: #fff;
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;
          text-align: left;
          color: #3C4654;
          box-shadow: none;
          padding: 11px 13px;
          width: 100%;

          &::placeholder {
            color: #99A0AA;
          }
        }

        .clsFormSelect {
          option:first-child {
            color: #99A0AA;
          }

          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 100%;
          background-image: url('assets/images/account-page/down-arrow.svg');
          background-repeat: no-repeat;
          background-position: right 10px center;
          background-size: 12px 12px;
          padding: 13px 13px;
        }

        .clsInputGroup {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          align-items: stretch;
          width: 100%;

          .clsFormControl {
            flex: 1;

            &:first-child {
              border-top-right-radius: 0px;
              border-bottom-right-radius: 0px;
              margin-right: 1px;
            }

            &:last-child {
              border-top-left-radius: 0px;
              border-bottom-left-radius: 0px;
              margin-left: 1px;
            }
          }
        }

        .clsInputCard {
          position: absolute;
          right: 13px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .clsModalFooter{
      padding: 24px 0;
      display: flex;
      justify-content: center;
      gap:24px;
      
      .text_primary {
          padding: 13px 19px;
          border-radius: 999px;
          margin: 0;
      
          &:hover {
            background-color: #DFE1E4;
          }
      
          &:active {
            background-color: #BCC1C7;
          }
        }
      
        .clsBtnOrng {
          padding: 13px 19px;
          margin: 0;
          border-radius: 999px;
      
          &:hover {
            background-color: #DFE1E4;
          }
      
          &:active {
            background-color: #BCC1C7;
          }
        }
    }
  }

  &.clsAccEditProfileModal,
  &.clsAccUpdateProfileModal{
    .clsModalBody {
      max-width: 539px;
    }
  }
 
}

.clsBtnOrng {
  background-color: #F16722 !important;
  border-radius: 999px;
    &:hover{
      background-color: #D34F0D !important; 
    }
    &:active{
      background-color: #AB400B !important; 
    }
    &:disabled{
      color: #99A0AA;
      background-color: #DFE1E4 !important; 
    }
}


.clsSelectProfileImg {
  .clstProfileImg {
    width: 128px;
    height: 128px;
    border-radius: 999px;
    overflow: hidden;
    background-color: #B8DFE6;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.clsAddTxt {
  padding: 11px;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: #128292;
  display: block;
  text-align: center;
  margin-top: 6px;
}

// clsInfoPopup
.clsInfoPopup {
  position: relative;

  .clsInfoIcon {
    cursor: pointer;
    margin-left: 4px;
    display: inline-block;

    // &:hover+.clsInfoPopupContent {
    //   visibility: visible;
    //   opacity: 1;
    // }
  }

  .clsInfoPopupContent {
    padding: 20px;
    border-radius: 8px;
    background-color: #128292;
    box-shadow: 0px 4px 6px 0px #00000029;
    position: absolute;
    width: 320px;
    bottom: 190%;
    left: 71%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
    text-transform: none;

    &::after {
      content: '';
      border-width: 10px;
      border-style: solid;
      border-color: #128292 transparent transparent transparent;
      position: absolute;
      top: 100%;
      left: 8%;
    }

    &.clsOpenPopup {
      visibility: visible;
      opacity: 1;
    }

    &.clsInfoPopupRight {
      left: unset;
      right: -17%;

      &::after {
        content: '';
        right: 8%;
        left: unset;
      }
    }

    .clsInfoContentTitle {
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      text-align: left;
      color: #FFFFFF;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .clsInfoContentPara {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      text-align: left;
      color: #FFFFFF;
      margin-bottom: 0px;
    }

    .clsCloseIcon {
      font-size: 24px;
      width: 22px;
      height: 22px;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      font-weight: bold;
      color: #94C9D1;
      text-align: center;
      cursor: pointer;
      transition: background-color 0.3s ease, color 0.3s ease;

      &:hover {
        color: #fff;
      }
    }
  }
}


// dropdown 
.dropdown {
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  cursor: pointer;
}

.open {
  display: block;
}

.dropdownList {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 5px 0px #00000033;
  background-color: #fff;
  border-radius: 12px;
  z-index: 1000;
  padding: 5px;

  .dropdownOptionList {
    padding: 10px;

    &:hover {
      background-color: #DFE1E4;
      border-radius: 12px;
    }

    .dropdownOptionList1 {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      color: #3C4654;
      margin-bottom: 5px;
    }

    .dropdownOptionList2 {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.8px;
      text-align: left;
      color: #66707D;
      margin: 0;
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.clsMainTitle {
  font-size: 36px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.02em;
  text-align: center;
  margin: 10px 0px;
  color: #3C4654;
}

.clsMainSubTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #3C4654;
  margin: 0;
}

.clsMainPara {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: center;
  margin: 0;
  padding-top: 10px;
  color: #66707D;
  margin: 0 auto;
}

.clsInnerMainBody {
  margin: -15px;

  .clsInnerPage {
    padding: 15px;
    height: calc(100vh - 170px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .clsInnerFooter {
    padding: 24px;
    background-color: #EFF2F6;
    box-shadow: inset 1px 0 0px 1px #BCC1C7;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      + {
        button {
          margin-left: 24px;
        }
      }
    }
  }
}


.clsInnerMainBody {
  margin: -15px;

  .clsInnerPage {
    padding: 15px;
    height: calc(100vh - 170px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .clsInnerFooter {
    padding: 24px;
    background-color: #EFF2F6;
    box-shadow: inset 1px 0 0px 1px #BCC1C7;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      + {
        button {
          margin-left: 24px;
        }
      }
    }
  }
}




// Lead Automator css start
.clsLeadContentCard {
  background-color: #128292;
  padding: 18px;
  border-radius: 12px;

  .clsLeadContentTxt1 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    color: #FFFFFF;
    margin: 0;
    margin-bottom: 18px;
  }

  .clsLeadContentList {
    display: flex;

    .clsLeadListLeft {
      padding: 0;
      margin: 0;
      list-style: none;
      flex: 1;

      li {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #FFFFFF;
        margin-bottom: 4px;
        padding-left: 32px;
        position: relative;

        &:last-child {
          margin: 0;
        }

        .clsLeadListChek {
          width: 24px;
          height: 24px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 0;

          &::before {
            content: '';
            display: inline-block;
            width: 7px;
            height: 12px;
            border: solid #fff;
            border-width: 0 2px 2px 0px;
            transform: rotate(40deg);
          }
        }

      }
    }

    .clsLeadContenRight {
      width: 117px;
      height: 117px;
      flex: 0 0 117px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.clsLAReviewPaySec {
  .clsLAReviewPayCard {
    padding: 24px;
    border-radius: 12px;
    background-color: #FFFFFF;

    .clsLAReviewLeadAutoDetils {
      margin-bottom: 16px;

      .clsLAReviewLeadAutoImgSmall {

        img {
          width: 100%;
          height: 100%;
        }
      }

      .clsLAReviewLeadAutomatorTxt {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: #3C4654;
        margin: 0;
        margin-left: 16px;
      }
    }

    .clsLAReviewTier {
      padding: 12px 16px;
      border-radius: 12px;
      background-color: #EFF2F6;

      .clsTxtLine1 {
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        text-align: left;
        color: #66707D;
      }

      .clsTxtLine2 {
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        text-align: left;
        color: #3C4654;
      }
    }
  }

  .clsLAReviewPayCard2 {
    padding: 0 48px;
    border-radius: 12px;
    background-color: #FFFFFF;

    .clsLAReviewHeading {
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.02em;
      text-align: center;
      color: #3C4654;
      margin: 0;
      padding-top: 48px;
    }

    .clsLAReviewPayTimelineContainer {
      padding: 48px 0;

      .clsLAReviewPayTimeline {
        position: relative;
        margin: 16px 0;

        .clsLATimelineLine {
          position: absolute;
          left: 3px;
          top: 9px;
          bottom: 10px;
          width: 2px;
          background: #128292;
        }

        .clsLATimelineLine2 {
          position: absolute;
          left: 3px;
          top: 9px;
          bottom: 95px;
          width: 2px;
          background: #128292;
        }

        .clsLATimelineItem {
          position: relative;
          padding-left: 20px;
          margin-bottom: 64px;

          &:last-child {
            margin-bottom: 0;

            .clsLATimelineDot {
              background: #FFFFFF;
              border: solid 2px #128292;
            }
          }

          .clsLATimelineDot {
            position: absolute;
            left: 0;
            top: 5px;
            width: 8px;
            height: 8px;
            background: #128292;
            border-radius: 50%;
          }

          .clsLATimelineContent {

            .clsLATimelineHead {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 8px;

              .clsLATimelineLabel {
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
                text-align: left;
                color: #3C4654;

              }

              .clsLATimelinePrice {
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
                text-align: left;
                color: #3C4654;
              }
            }

            .clsLATimelineDescription {
              font-size: 16px;
              font-weight: 500;
              line-height: 19.2px;
              text-align: left;
              color: #66707D;

            }
          }  
        }
      }
    }

    .clsLAReviewPMCard {
      background-color: #EFF2F6;
      padding: 24px;
      border-radius: 16px;

      .clsLAReviewPMCardHeading {
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
        color: #3C4654;
        margin-bottom: 16px;
      }

      .clsDropdownCard {
        background-color: #DFE1E4;
        border-radius: 12px;
        padding: 2px 16px 2px 2px;
        display: flex;
        align-items: center;
        min-width: 300px;
        min-height:73px;

        &:hover {
          background-color: #BCC1C7;
        }

        &.clsDropdownCardOpen {
          background-color: #128292;
        }
      }

      .clsPayCard {
        background-color: #FFFFFF;
        border-radius: 12px;
        padding: 16px 24px;
        display: inline-flex;
        border: solid 1px #FFFFFF;
        transition: border-color 0.3s ease;
        min-width: 251px;
        min-height: 69px; 

        .clsCustomCheckbox input[type="checkbox"]:checked~& {
          border-color: #128292;
        }

        &.clsPayCardFull {
          display: flex !important;
        }

        .clsMediaTxt1 {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          color: #3C4654;
          margin-bottom: 0;
        }

        .clsMediaTxt2 {
          font-size: 14px;
          font-weight: 500;
          line-height: 16.8px;
          color: #66707D;
          margin-bottom: 0;
        }
      }
    }

    .clsLAReviewContentPara {
      padding-bottom: 48px;

      .clsPara {
        font-size: 16px;
        font-weight: 500;
        line-height: 20.8px;
        text-align: left;
        color: #3C4654;
        margin: 0;
      }
    }
  }
}

.clsLeamAutoMainSec {

  .clsLAMainLeft {
    .clsLAMainHeading {
      font-size: 32px;
      font-weight: 600;
      line-height: 48px;
      text-align: center;
      color: #3C4654;
    }

    .clsLAImgLarge {
      text-align: center;
      width: 188px;
      margin: 48px auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .clsLAMainPara {
      font-size: 15px;
      font-weight: 600;
      line-height: 22.5px;
      text-align: left;
      color: #66707D;
    }
  }

  .clsLAMainRight {
    .clsLASubscribeList {
      padding: 0;
      margin: 0;
      list-style: none;

      .clsLASubscribeItem {
        margin-bottom: 16px;

        .clsLASubscribeLink {
          background-color: #fff;
          box-shadow: inset 0 0 0 1px #DFE1E4;
          border-radius: 12px;
          padding: 16px 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          // &:active {
          //   box-shadow: 0 0 0 2px #128292;
          // }

          &.selected {
            box-shadow: 0 0 0 2px #128292;
          }

          .clsLASubscribeLeftTxt {
            .clsLASubscribeTxt1 {
              font-size: 20px;
              font-weight: 700;
              line-height: 20px;
              text-align: left;
              color: #3C4654;
              margin-bottom: 2px;
            }

            .clsLASubscribeTxt2 {
              font-size: 14px;
              font-weight: 500;
              line-height: 14px;
              text-align: left;
              color: #66707D;
              margin: 0;
            }
          }

          .clsLASubscribeBtn {
            .btn_primary {
              font-size: 12px;
              font-weight: 600;
              line-height: 12px;
              text-align: center;
              color: #FFFFFF;
              padding: 4px 12px;
            }
          }

          .clsLASubscribeRightTxt {
            .clsLASubscribeTxt1 {
              font-size: 20px;
              font-weight: 600;
              line-height: 20px;
              text-align: right;
              color: #3C4654;
              margin: 0;

              sub {
                font-size: 14px;
                font-weight: 600;
                line-height: 14px;
                bottom: 0;
              }
            }
          }
        }
      }
    }

    .clsLAUnSubscribeTxt {
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      text-align: center;
      color: #128292;
      margin: 0;
      padding: 8px 14px;
      display: inline-flex;
      cursor: pointer;
    }
  }
}

// Lead Automator end 

.profile {
  color: $color-grey;
  font-size: 14px;
  position: absolute;
  left: 50px;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  padding-bottom: 10px;
  background-color: #EEF2F6;
}

.clsFormErrorText {
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  color: #CA1551;
  padding-top: 6px;
}